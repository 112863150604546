// import {
//   useNavigate,
//   useLocation,
//   useParams,
//   useRouteMatch,
// } from 'react-router';
// import { useMemo } from 'react';
import dayjs from 'dayjs';
import lodash from 'lodash';

// import queryString from 'query-string';
import { BaseEntity } from '@core/entity';
import { PaginationEntity } from '@core/pagination/entity';
import httpRepository from '@core/repository/http';
import { OptionEntity, TableEntity } from '@core/table';
import { IFeatureRestrictions } from '@shared/components/InputVoucherType';
import { CONST_ALL } from '@view/BlackList/interface';

export function paginationRepository<T extends BaseEntity>(
  type: { new (bas: Partial<T>): T },
  p: {
    pagination: PaginationEntity;
    options: OptionEntity;
    path: string;
    showSuccess?: boolean;
    showError?: boolean;
  },
) {
  const params = new TableEntity(p.pagination, p.options);
  return httpRepository.execute({
    method: 'get',
    ...p,
    params,
    convert: res => {
      return {
        data: BaseEntity.toList(type, res?.pagedData),
        info: new PaginationEntity(res?.pageInfo),
        moreInfo: res?.moreInfo,
      };
    },
  });
}

export const indexOfArrayObject = (array: any[], key: string, value: any) => {
  if (!Array.isArray(array)) {
    return;
  }
  let index = -1;
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    if (item[key] === value) {
      index = i;
      break;
    }
  }
  return index;
};

export const debounce = (callback: any, delay: number) => {
  return lodash.debounce(callback, delay);
};

export const onScrollBottom = (callBack: any) => {
  window.onscroll = function (event) {
    if (window.innerHeight + window.scrollY > document.body.offsetHeight) {
      callBack(event);
    }
  };
};

export function roundToTwo(num: string) {
  return Number.parseFloat(num).toFixed(2);
}

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  const random = Math.random();
  return Math.floor(random * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}
export function isValidHttpUrl(string: string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

export const spliceArray = (arr: Array<any>, start: number, end: number) => {
  return [...arr].splice(start, end);
};

export const getCookie = (cname: string) => {
  const name = cname + '=';
  const decodedCookie: string = decodeURIComponent(document.cookie) || '';
  if (decodedCookie == null || decodedCookie === '') {
    return '';
  }
  const cookieValue = decodedCookie
    .split('; ')
    ?.find(row => row.startsWith(name))
    ?.split('=')[1];
  return cookieValue || '';
};

export const toFirstUpperCase = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const toFirstLowerCase = (string: string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function sessionStorageSetItem(key: string, data: any) {
  sessionStorage.setItem(key, JSON.stringify(data));
}

export function sessionStorageGetItem(key: string) {
  const dataJson = sessionStorage.getItem(key);
  if (dataJson != null) {
    return JSON.parse(dataJson);
  } else {
    return undefined;
  }
}

export const delay = (milliSecond: any) => new Promise(resolve => setTimeout(resolve, milliSecond));

export const counterNumber = (value: number) => {
  if (value >= 1000000) {
    return Math.floor(value / 1000000);
    // return (value / 1000000)?.toFixed(3)?.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  } else if (value >= 1000) {
    return Math.floor(value / 1000);
    // return (value / 1000)?.toFixed(3)?.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  } else {
    return value;
  }
};

export const dateToString = (date: any, format: string) => {
  if (!dayjs(date).isValid()) {
    return '';
  }
  return dayjs(date).format(format);
};
export const removeNullFields = values => {
  for (const key in values) {
    if (values[key] === null) {
      values[key] = undefined;
    }
  }
  return values;
};
export const roundOff = (num: number | null | undefined, decimal_places: number = 2) => {
  if (num === null || num === undefined) {
    return '--';
  }
  return parseFloat(num.toFixed(decimal_places));
};
export const isWithinInterval = (
  intervalStart: any,
  intervalEnd: any,
  currentTime: any = dayjs(),
) => {
  intervalStart = dayjs(intervalStart);
  intervalEnd = dayjs(intervalEnd);
  currentTime = dayjs(currentTime);

  if (!intervalStart.isValid() || !intervalEnd.isValid() || !currentTime.isValid()) {
    return 0;
  }

  if (currentTime.isBefore(intervalStart)) {
    return 1;
  }
  if (currentTime.isBetween(intervalStart, intervalEnd)) {
    return 2;
  }
  if (currentTime.isAfter(intervalEnd)) {
    return 3;
  }
};
export function pad(number) {
  return String(number).padStart(2, '0');
}
export function formatTimeZoneOffset(offset) {
  const sign = offset >= 0 ? '' : '-';
  const absOffset = Math.abs(offset);
  const hours = Math.floor(absOffset / 60);
  const minutes = absOffset % 60;

  return `${sign}${pad(hours)}:${pad(minutes)}:00`;
}

export const isObjectEmpty = obj => {
  return !Object.values(obj).some(item => !lodash.isEmpty(item));
};

export const convertDecimalToBinary = (num: number) => {
  return num.toString(2);
};

export const getKeyAndValue = (array: IFeatureRestrictions[]) => {
  if (!array) {
    return undefined;
  }

  const hasNullValue = array
    ?.filter((item: IFeatureRestrictions) => item?.checked === true)
    ?.some((item: IFeatureRestrictions) => item.value === null);
  if (hasNullValue) {
    return undefined;
  }

  const result = array
    ?.filter((item: IFeatureRestrictions) => item?.myId !== CONST_ALL)
    ?.filter((item: IFeatureRestrictions) => item.value != null)
    ?.map((item: IFeatureRestrictions) => {
      return {
        featureGuardId: item?.featureGuardId,
        type: 1,
        value: String(item?.value),
        id: item?.id,
      };
    });
  if (result?.length === 0) {
    return undefined;
  }
  return result;
};

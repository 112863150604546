export default {
  'server.notFound': 'Not found',
  'server.networkError': 'Network error',
  'Server.AddBeat.Success': 'Adding success',
  'Server.DeleteBeat.Success': 'Deleting success',
  'Server.UpdateBeat.Success': 'Updating success',
  'Mes.Users.Add.Successfully': 'Add successful account',
  'Mes.Users.DeleteUsers.Successfully': 'Successfully erase',
  'Mes.Users.UpdateSuccessfully': 'successfulAccountInformationUpdates',
  'Mes.Researchs.Update.Successfully': 'Updating successful research',
  'Mes.Researchs.Add.Successfully': 'Add a successful study',
  'Mes.Researchs.Delete.Successfully': 'Delete successful research',
  'server.data.success': 'Successful',
  Successful: 'Successful',

  'Mes.User.SendPasswordRecoveryCodeSuccessfully': 'The recovery link has been sent to your email',
  'Mes.User.ResetPasswordSuccessfully': 'Successful password reset',
  'Mes.Profile.GetDetailSuccessfully': 'Get successful account information',
  'Mes.Profile.OldPassword.Wrong': 'The old password is not correct',
  'Mes.Profile.ChangePasswordSuccessfully': 'Successful password update',
  'Mes.Role.UpdateSuccessfully': 'Update the role of success',
  'Mes.Role.CreateSuccessfully': 'Add a successful role ',
  'Mes.Role.DeleteSuccessfully': 'Delete the role of success',
  'Mes.User.Wrong.Password': 'Invalid password',

  //=======================================================
  //=======================================================
  //=======================================================
  //=======================================================
  //=======================================================
  //=======================================================
  //=======================================================
  //=======================================================
  //=======================================================
  'Mes.Role.Create.Successfully': 'Role created successfully',
  'Mes.Role.Create.Failed': 'Failed to create role',
  'Mes.Role.Update.Successfully': 'Role updated successfully',
  'Mes.Role.Update.Failed': 'Failed to update role',
  'Mes.Role.Search.Successfully': 'Successfully viewed role list',
  'Mes.Role.ViewDetail.Successfully': 'Successfully viewed role details',
  'Mes.Role.Delete.Successfully': 'Role deleted successfully',
  'Mes.Role.Delete.Failed': 'Failed to delete role',
  'Mes.Role.AlreadyExist.Code': 'Role code already exists',
  'Mes.Role.Required.Code': 'Role code is required',
  'Mes.Role.NotWhiteSpace.Code': 'Role code must not contain whitespace',
  'Mes.Role.NotSpecialCharacter.Code':
    'Role code must not contain special characters (except . and @)',
  'Mes.Role.Required.Name': 'Role name is required',
  'Mes.Role.OverLength.Name': 'Role name must not exceed 255 characters',
  'Mes.Role.Required.PermissionCodes': 'Permission codes are required',
  'Mes.Role.NotFound': 'Role not found',
  'Mes.Role.WasUsed': 'Role has been used',
  'Mes.User.Create.Successfully': 'User created successfully',
  'Mes.User.Create.Failed': 'Failed to create user',
  'Mes.User.Update.Successfully': 'User updated successfully',
  'Mes.User.Update.Failed': 'Failed to update user',
  'Mes.User.Search.Successfully': 'Successfully viewed user list',
  'Mes.User.ViewDetail.Successfully': 'Successfully viewed user details',
  'Mes.User.Delete.Successfully': 'User deleted successfully',
  'Mes.User.Delete.Failed': 'Failed to delete user',
  'Mes.User.Login.Successfully': 'Logged in successfully',
  'Mes.User.Send.Failed': 'Failed to send email to user',
  'Mes.User.CheckRecoveryCode.Successfully': 'Recovery code checked successfully',
  'Mes.User.SendRecoveryCode.Successfully': 'Recovery code sent successfully',
  'Mes.User.ResetPassword.Successfully': 'Password reset successfully',
  'Mes.User.Refresh.Successfully': 'Refreshed successfully',
  'Mes.User.ChangePassword.Successfully': 'Password changed successfully',
  'Mes.User.Profile.Successfully': 'Profile information retrieved successfully',
  'Mes.User.Invalid.Username': 'Invalid username',
  'Mes.User.AlreadyExist.Username': 'Username already exists',
  'Mes.User.Required.Username': 'Username is required',
  'Mes.User.NotWhiteSpace.Username': 'Username must not contain whitespace',
  'Mes.User.NotSpecialCharacter.Username':
    'Username must not contain special characters (except . and @)',
  'Mes.User.Invalid.Email': 'Invalid email',
  'Mes.User.AlreadyExist.Email': 'Email already exists',
  'Mes.User.Required.Email': 'Email is required',
  'Mes.User.NotWhiteSpace.Email': 'Email must not contain whitespace',
  'Mes.User.OverLength.Name': 'User name must not exceed 250 characters',
  'Mes.User.Invalid.AvatarPicture': 'Invalid avatar picture',
  'Mes.User.Invalid.Password': 'Invalid password',
  'Mes.User.Required.Password': 'Password is required',
  'Mes.User.IsWrong.OldPassword': 'Old password is incorrect',
  'Mes.User.Invalid.OldPassword': 'Invalid old password',
  'Mes.User.Invalid.PhoneNumber': 'Invalid phone number',
  'Mes.User.Required.RoleId': 'Role ID is required',
  'Mes.User.Status.Required': 'Status is required',
  'Mes.User.Status.Invalid': 'Invalid status',
  'Mes.User.Required.RecoveryCode': 'Recovery code is required',
  'Mes.User.NotFound.RecoveryCode': 'User recovery code not found',
  'Mes.User.NotFound': 'User not found',
  'Mes.User.PleaseCheckEmail': 'Please check your email',
  'Mes.User.Blocked': 'User is blocked',
  'Mes.UserRefreshToken.Required.Token': 'Refresh token is required',
  'Mes.UserRefreshToken.WasUsed': 'Refresh token has been used',
  'Mes.UserRefreshToken.Invalid.Token': 'Invalid refresh token',
  'Mes.UserRefreshToken.Expired.Token': 'Refresh token has expired',
  'Mes.Customer.Delete.Failed': 'Failed to delete customer',
  'Mes.Customer.Delete.Successfully': 'Customer deleted successfully',
  'Mes.Customer.Distribute.Failed': 'Failed to distribute customer',
  'Mes.Customer.Distribute.Successfully': 'Customer distributed successfully',
  'Mes.Customer.Search.Failed': 'Failed to search for customers',
  'Mes.Customer.Search.Successfully': 'Customer search successful',
  'Mes.Customer.ViewList.Failed': 'Failed to view customer list',
  'Mes.Customer.ViewList.Successfully': 'Successfully viewed customer list',
  'Mes.Customer.Equip.Failed': 'Failed to equip customer',
  'Mes.Customer.Equip.Successfully': 'Successfully equipped customer',
  'Mes.Customer.Purchase.Failed': 'Failed to purchase for customer',
  'Mes.Customer.Purchase.Successfully': 'Successfully made customer purchase',
  'Mes.Customer.ViewDetail.Failed': 'Failed to view customer details',
  'Mes.Customer.ViewDetail.Successfully': 'Successfully viewed customer details',
  'Mes.Customer.Update.Failed': 'Failed to update customer',
  'Mes.Customer.Update.Successfully': 'Successfully updated customer',
  'Mes.Customer.Create.Failed': 'Failed to create customer',
  'Mes.Customer.Create.Successfully': 'Successfully created customer',
  'Mes.Customer.Expired.OtpCode': 'Customer OTP code expired',
  'Mes.Customer.AlreadyExist.PhoneNumber': 'Customer phone number already exists',
  'Mes.Customer.AlreadyExist.NickName': 'Customer nickname already exists',
  'Mes.Customer.NotEnoughLength.Id': 'Customer ID length is not sufficient',
  'Mes.Customer.NotEnough.AccumulatedCoins': 'Customer does not have enough accumulated coins',
  'Mes.Customer.OverLength.NickName': 'Customer nickname is too long',
  'Mes.Customer.NotSpecialCharacter.NickName':
    'Customer nickname should not contain special characters (except . @)',
  'Mes.Customer.Required.Id': 'Customer ID is required',
  'Mes.Customer.Required.OtpCode': 'Customer OTP code is required',
  'Mes.Customer.Required.Bio': 'Customer bio is required',
  'Mes.Customer.Required.Gender': 'Customer gender is required',
  'Mes.Customer.Required.Status': 'Customer status is required',
  'Mes.Customer.Required.AccumulatedCoins': 'Customer accumulated coins are required',
  'Mes.Customer.Required.PhoneNumber': 'Customer phone number is required',
  'Mes.Customer.Required.NickName': 'Customer nickname is required',
  'Mes.Customer.Invalid.Id': 'Invalid customer ID',
  'Mes.Customer.Invalid.OtpCode': 'Invalid customer OTP code',
  'Mes.Customer.Invalid.Gender': 'Invalid customer gender',
  'Mes.Customer.Invalid.Status': 'Invalid customer status',
  'Mes.Customer.Invalid.AccumulatedCoins': 'Invalid customer accumulated coins',
  'Mes.Customer.Invalid.PhoneNumber': 'Invalid customer phone number',
  'Mes.Customer.Invalid.NickName': 'Invalid customer nickname',
  'Mes.Customer.NotFound.Id': 'Customer ID not found',
  'Mes.Customer.NotFound.OtpCode': 'OTP code not found for customer',
  'Mes.Customer.NotFound.PhoneNumber': 'Customer phone number not found',
  'Mes.Customer.NotFound.NickName': 'Customer nickname not found',
  'Mes.Customer.NotFound': 'Customer not found',
  'Mes.Customer.WasUsed.OtpCodeExpired': 'Customer OTP code has expired and was already used',
  'Mes.Customer.WasUsed.OtpCodeVerified': 'Customer OTP code has been used and verified',
  'Mes.Customer.WasUsed.OtpCode': 'Customer OTP code has already been used',
  'Mes.Customer.Blocked': 'Customer is blocked',
  'Mes.Customer.Required.Code': 'Customer code is required',
  'Mes.CustomerRefreshToken.Invalid.Token': 'Invalid customer refresh token',
  'Mes.CustomerRefreshToken.Expired.Token': 'Customer refresh token has expired',
  'Mes.Customer.Invalid.Method': 'Invalid customer OTP sending method',
  'Mes.Customer.Required.Method': 'Customer OTP sending method is required',
  'Mes.Customer.Invalid.ReCaptcha': 'Invalid customer reCaptcha',
  'Mes.Province.NotFound.Id': 'Province ID not found',
  'Mes.District.NotFound.Id': 'District ID not found',
  'Mes.Commune.NotFound.Id': 'Commune ID not found',
  'Mes.CollectHistory.Required.ValidQuantity': 'Valid quantity of Aqua bottles is required',
  'Mes.CollectHistory.Required.InvalidQuantity': 'Invalid quantity of non-Aqua bottles is required',
  'Mes.CollectHistory.Invalid.Quantity': 'Invalid bottle quantity',
  'Mes.CollectHistory.Required.Time': 'Time is required',
  'Mes.CollectHistory.Required.PayloadId': 'Payload ID is required',
  'Mes.CollectHistory.Required.SignData': 'Signature data is required',
  'Mes.CollectHistory.NotFound.DeviceId': 'Device ID not found',
  'Mes.Customer.SendOtp.Failed': 'Failed to send OTP to customer',
  'Mes.ItemStorage.NotFound.CustomerId': 'Item storage not found for user',
  'Mes.Collection.Delete.Failed': 'Failed to delete collection',
  'Mes.Collection.Delete.Successfully': 'Successfully deleted collection',
  'Mes.Collection.Distribute.Failed': 'Failed to distribute collection',
  'Mes.Collection.Distribute.Successfully': 'Successfully distributed collection',
  'Mes.Collection.Search.Failed': 'Failed to search for collections',
  'Mes.Collection.Search.Successfully': 'Successfully searched for collections',
  'Mes.Collection.ViewList.Failed': 'Failed to view collection list',
  'Mes.Collection.ViewList.Successfully': 'Successfully viewed collection list',
  'Mes.Collection.Purchase.Failed': 'Failed to purchase collection',
  'Mes.Collection.Purchase.Successfully': 'Successfully purchased collection',
  'Mes.Collection.ViewDetail.Failed': 'Failed to view collection details',
  'Mes.Collection.ViewDetail.Successfully': 'Successfully viewed collection details',
  'Mes.Collection.Update.Failed': 'Failed to update collection',
  'Mes.Collection.Update.Successfully': 'Successfully updated collection',
  'Mes.Collection.Create.Failed': 'Failed to create collection',
  'Mes.Collection.Create.Successfully': 'Successfully created collection',
  'Mes.Collection.Expired': 'Collection has expired',
  'Mes.Collection.Expired.Id': 'Collection ID has expired',
  'Mes.Collection.Expired.Items': 'Collection items have expired',
  'Mes.Collection.Expired.RepeatTimes': 'Collection repeat times have expired',
  'Mes.Collection.Expired.EndTime': 'Collection end time has expired',
  'Mes.Collection.Expired.StartTime': 'Collection start time has expired',
  'Mes.Collection.Expired.Name': 'Collection name has expired',
  'Mes.Collection.Expired.Code': 'Collection code has expired',
  'Mes.Collection.AlreadyExist.Id': 'Collection ID already exists',
  'Mes.Collection.AlreadyExist.Items': 'Collection items already exist',
  'Mes.Collection.AlreadyExist.RepeatTimes': 'Collection repeat times already exist',
  'Mes.Collection.AlreadyExist.EndTime': 'Collection end time already exists',
  'Mes.Collection.AlreadyExist.StartTime': 'Collection start time already exists',
  'Mes.Collection.AlreadyExist.Name': 'Collection name already exists',
  'Mes.Collection.AlreadyExist.Code': 'Collection code already exists',
  'Mes.Collection.NotEnoughLength.Id': 'Collection ID length is not sufficient',
  'Mes.Collection.NotEnoughLength.Items': 'Insufficient length of collection items',
  'Mes.Collection.NotEnoughLength.RepeatTimes': 'Insufficient length of collection repeat times',
  'Mes.Collection.NotEnoughLength.EndTime': 'Insufficient length of collection end time',
  'Mes.Collection.NotEnoughLength.StartTime': 'Insufficient length of collection start time',
  'Mes.Collection.NotEnoughLength.Name': 'Collection name length is not sufficient',
  'Mes.Collection.NotEnoughLength.Code': 'Collection code length is not sufficient',
  'Mes.Collection.NotEnough.Id': 'Insufficient collection ID',
  'Mes.Collection.NotEnough.Items': 'Insufficient Collection Items',
  'Mes.Collection.NotEnough.RepeatTimes': 'Insufficient Collection Repeat Times',
  'Mes.Collection.NotEnough.EndTime': 'Insufficient Collection End Time',
  'Mes.Collection.NotEnough.StartTime': 'Insufficient Collection Start Time',
  'Mes.Collection.NotEnough.Name': 'Insufficient Collection Name',
  'Mes.Collection.NotEnough.Code': 'Insufficient Collection Code',
  'Mes.Collection.OverLength.Id': 'Collection Id Too Long',
  'Mes.Collection.OverLength.Items': 'Collection Items Too Long',
  'Mes.Collection.OverLength.RepeatTimes': 'Collection Repeat Times Too Long',
  'Mes.Collection.OverLength.EndTime': 'Collection End Time Exceeds Length',
  'Mes.Collection.OverLength.StartTime': 'Collection Start Time Exceeds Length',
  'Mes.Collection.OverLength.Name': 'Collection Name Too Long',
  'Mes.Collection.OverLength.Code': 'Collection Code Too Long',
  'Mes.Collection.NotSpecialCharacter.Id': 'Collection Id Contains No Special Characters',
  'Mes.Collection.NotSpecialCharacter.Items': 'Collection Items Contain No Special Characters',
  'Mes.Collection.NotSpecialCharacter.RepeatTimes':
    'Collection Repeat Times Contains No Special Characters',
  'Mes.Collection.NotSpecialCharacter.EndTime':
    'Collection End Time Contains No Special Characters',
  'Mes.Collection.NotSpecialCharacter.StartTime':
    'Collection Start Time Contains No Special Characters',
  'Mes.Collection.NotSpecialCharacter.Name': 'Collection Name Contains No Special Characters',
  'Mes.Collection.NotSpecialCharacter.Code': 'Collection Code Contains No Special Characters',
  'Mes.Collection.NotWhiteSpace.Id': 'Collection Id Cannot Be Whitespace',
  'Mes.Collection.NotWhiteSpace.Items': 'Collection Items Cannot Be Whitespace',
  'Mes.Collection.NotWhiteSpace.RepeatTimes': 'Collection Repeat Times Cannot Be Whitespace',
  'Mes.Collection.NotWhiteSpace.EndTime': 'Collection End Time Cannot Be Whitespace',
  'Mes.Collection.NotWhiteSpace.StartTime': 'Collection Start Time Cannot Be Whitespace',
  'Mes.Collection.NotWhiteSpace.Name': 'Collection Name Cannot Be Whitespace',
  'Mes.Collection.NotWhiteSpace.Code': 'Collection Code Cannot Be Whitespace',
  'Mes.Collection.Required.Id': 'Collection Id is Required',
  'Mes.Collection.Required.Items': 'Collection Items are Required',
  'Mes.Collection.Required.RepeatTimes': 'Collection Repeat Times is Required',
  'Mes.Collection.Required.EndTime': 'Collection End Time is Required',
  'Mes.Collection.Required.StartTime': 'Collection Start Time is Required',
  'Mes.Collection.Required.Name': 'Collection Name is Required',
  'Mes.Collection.Required.Code': 'Collection Code is Required',
  'Mes.Collection.MustBeEmpty.Id': 'Collection Id must be empty',
  'Mes.Collection.MustBeEmpty.Items': 'Collection Items must be empty',
  'Mes.Collection.MustBeEmpty.RepeatTimes': 'Collection Repeat Times must be empty',
  'Mes.Collection.MustBeEmpty.EndTime': 'Collection End Time must be empty',
  'Mes.Collection.MustBeEmpty.StartTime': 'Collection Start Time must be empty',
  'Mes.Collection.MustBeEmpty.Name': 'Collection Name must be empty',
  'Mes.Collection.MustBeEmpty.Code': 'Collection Code must be empty',
  'Mes.Collection.Invalid.Id': 'Invalid Collection Id',
  'Mes.Collection.Invalid.Items': 'Invalid Collection Items',
  'Mes.Collection.Invalid.RepeatTimes': 'Invalid Collection Repeat Times',
  'Mes.Collection.Invalid.EndTime': 'Invalid Collection End Time',
  'Mes.Collection.Invalid.StartTime': 'Invalid Collection Start Time',

  'Mes.Collection.Invalid.Name': 'Invalid collection name',
  'Mes.Collection.Invalid.Code': 'Invalid collection code',
  'Mes.Collection.Repeated.Id': 'Repeated collection ID',
  'Mes.Collection.Repeated.Items': 'Repeated collection items',
  'Mes.Collection.Repeated.RepeatTimes': 'Repeated collection repeat times',
  'Mes.Collection.Repeated.EndTime': 'Repeated collection end time',
  'Mes.Collection.Repeated.StartTime': 'Repeated collection start time',
  'Mes.Collection.Repeated.Name': 'Repeated collection name',
  'Mes.Collection.Repeated.Code': 'Repeated collection code',
  'Mes.Collection.NotFound.Id': 'Collection ID not found',
  'Mes.Collection.NotFound.Items': 'Collection items not found',
  'Mes.Collection.NotFound.RepeatTimes': 'Collection repeat times not found',
  'Mes.Collection.NotFound.EndTime': 'Collection end time not found',
  'Mes.Collection.NotFound.StartTime': 'Collection start time not found',
  'Mes.Collection.NotFound.Name': 'Collection name not found',
  'Mes.Collection.NotFound.Code': 'Collection code not found',
  'Mes.Collection.NotFound': 'Collection not found',
  'Mes.Collection.WasUsed.Id': 'Collection ID has been used',
  'Mes.Collection.WasUsed.Items': 'Collection items have been used',
  'Mes.Collection.WasUsed.RepeatTimes': 'Collection repeat times have been used',
  'Mes.Collection.WasUsed.EndTime': 'Collection end time has been used',
  'Mes.Collection.WasUsed.StartTime': 'Collection start time has been used',
  'Mes.Collection.WasUsed.Name': 'Collection name has been used',
  'Mes.Collection.WasUsed.Code': 'Collection code has been used',
  'Mes.Collection.WasUsed': 'Collection has been used',
  'Mes.ItemGroup.Delete.Failed': 'Failed to delete item group',
  'Mes.ItemGroup.Delete.Successfully': 'Item group deleted successfully',
  'Mes.ItemGroup.Distribute.Failed': 'Failed to distribute item group',
  'Mes.ItemGroup.Distribute.Successfully': 'Item group distributed successfully',
  'Mes.ItemGroup.Search.Failed': 'Failed to search item group',
  'Mes.ItemGroup.Search.Successfully': 'Item group searched successfully',
  'Mes.ItemGroup.ViewList.Failed': 'Failed to view item group list',
  'Mes.ItemGroup.ViewList.Successfully': 'Item group list viewed successfully',
  'Mes.ItemGroup.Equip.Failed': 'Failed to equip item group',
  'Mes.ItemGroup.Equip.Successfully': 'Item group equipped successfully',
  'Mes.ItemGroup.ViewDetail.Failed': 'Failed to view item group details',
  'Mes.ItemGroup.ViewDetail.Successfully': 'Item group details viewed successfully',
  'Mes.ItemGroup.Update.Failed': 'Failed to update item group',
  'Mes.ItemGroup.Create.Failed': 'Failed to create item group',
  'Mes.ItemGroup.Create.Successfully': 'Item group created successfully',
  'Mes.ItemGroup.AlreadyExist.Id': 'Item group ID already exists',
  'Mes.ItemGroup.AlreadyExist.Items': 'Item group already exists',
  'Mes.ItemGroup.AlreadyExist.Name': 'Item group name already exists',
  'Mes.ItemGroup.AlreadyExist.Code': 'Item group code already exists',
  'Mes.ItemGroup.NotEnoughLength.Code': 'Insufficient length for item group code',
  'Mes.ItemGroup.NotEnough.Id': 'Insufficient item group ID',
  'Mes.ItemGroup.NotEnough.Items': 'Insufficient item group items',
  'Mes.ItemGroup.NotEnough.Name': 'Insufficient item group name',
  'Mes.ItemGroup.NotEnough.Code': 'Insufficient item group code',
  'Mes.ItemGroup.OverLength.Id': 'Item group ID is too long',
  'Mes.ItemGroup.OverLength.Items': 'Item group items are too long',
  'Mes.ItemGroup.OverLength.Name': 'Item group name is too long',
  'Mes.ItemGroup.OverLength.Code': 'Item group code is too long',
  'Mes.ItemGroup.Required.Id': 'Item group ID is required',
  'Mes.ItemGroup.Required.Items': 'Item group items are required',
  'Mes.ItemGroup.Required.Name': 'Item group name is required',
  'Mes.ItemGroup.Required.Code': 'Item group code is required',
  'Mes.ItemGroup.MustBeEmpty.Id': 'Item group ID must be empty',
  'Mes.ItemGroup.MustBeEmpty.Items': 'Item group must be empty',
  'Mes.ItemGroup.MustBeEmpty.Name': 'Item group name must be empty',
  'Mes.ItemGroup.MustBeEmpty.Code': 'Item group code must be empty',
  'Mes.ItemGroup.Invalid.Id': 'Invalid item group ID',
  'Mes.ItemGroup.Invalid.Items': 'Invalid item group items',
  'Mes.ItemGroup.Invalid.Name': 'Invalid item group name',
  'Mes.ItemGroup.Invalid.Code': 'Invalid item group code',
  'Mes.ItemGroup.Repeated.Id': 'Repeated item group ID',
  'Mes.ItemGroup.Repeated.Items': 'Repeated item group',
  'Mes.ItemGroup.Repeated.Name': 'Repeated item group name',
  'Mes.ItemGroup.Repeated.Code': 'Repeated item group code',
  'Mes.ItemGroup.NotFound.Id': 'Item group ID not found',
  'Mes.ItemGroup.NotFound.Items': 'Item group not found',
  'Mes.ItemGroup.NotFound.Name': 'Item group name not found',
  'Mes.ItemGroup.NotFound.Code': 'Item group code not found',
  'Mes.ItemGroup.NotFound': 'Item group not found',
  'Mes.ItemGroup.WasUsed.Id': 'Item group ID has been used',
  'Mes.ItemGroup.WasUsed.Items': 'Item group items have been used',
  'Mes.ItemGroup.WasUsed.Name': 'Item group name has been used',
  'Mes.ItemGroup.WasUsed.Code': 'Item group code has been used',
  'Mes.ItemGroup.WasUsed': 'Item group has been used',
  'Mes.ItemGroup.Blocked': 'Item group is blocked',
  'Mes.Item.Delete.Failed': 'Failed to delete item',
  'Mes.Item.Delete.Successfully': 'Item deleted successfully',
  'Mes.Item.Distribute.Failed': 'Failed to distribute item',
  'Mes.Item.Distribute.Successfully': 'Item distributed successfully',
  'Mes.Item.Search.Failed': 'Failed to search item',
  'Mes.Item.Search.Successfully': 'Item searched successfully',
  'Mes.Item.ViewList.Failed': 'Failed to view item list',
  'Mes.Item.ViewList.Successfully': 'Item list viewed successfully',
  'Mes.Item.Equip.Failed': 'Failed to equip item',
  'Mes.Item.Equip.Successfully': 'Item equipped successfully',
  'Mes.Item.Purchase.Failed': 'Failed to purchase item',
  'Mes.Item.Purchase.Successfully': 'Item purchased successfully',
  'Mes.Item.ViewDetail.Failed': 'Failed to view item details',
  'Mes.Item.ViewDetail.Successfully': 'Item details viewed successfully',
  'Mes.Item.Update.Failed': 'Failed to update item',
  'Mes.Item.Update.Successfully': 'Item updated successfully',
  'Mes.Item.Create.Failed': 'Failed to create item',
  'Mes.Item.Create.Successfully': 'Item created successfully',
  'Mes.Item.Expired': 'Item has expired',
  'Mes.Item.Expired.Id': 'Item ID has expired',
  'Mes.Item.Expired.Events': 'Item events have expired',
  'Mes.Item.Expired.OwnPreferentials': 'Own preferentials of the item have expired',
  'Mes.Item.Expired.Preferentials': 'Item preferentials have expired',
  'Mes.Item.Expired.ItemStorages': 'Item storages have expired',
  'Mes.Item.Expired.SoldQuantity': 'Sold quantity of expired items',
  'Mes.Item.Expired.SortType': 'Sort type of expired items',
  'Mes.Item.Expired.BusinessStatus': 'Business status of expired items',
  'Mes.Item.Expired.GenderType': 'Gender type of expired items',
  'Mes.Item.Expired.ItemGroup': 'Expired item group',
  'Mes.Item.Expired.ItemGroupId': 'Expired item group ID',
  'Mes.Item.Expired.Collection': 'Expired item collection',
  'Mes.Item.Expired.CollectionId': 'Expired item collection ID',
  'Mes.Item.Expired.Overlay': 'Overlay of expired items',
  'Mes.Item.Expired.Thumbnail': 'Thumbnail of expired items',
  'Mes.Item.Expired.High': 'Expired high items',
  'Mes.Item.Expired.Abscissa': 'Expired item',
  'Mes.Item.Expired.Ordinate': 'Expired item',
  'Mes.Item.Expired.ConfigurationObj': 'Expired item configuration object',
  'Mes.Item.Expired.Configuration': 'Expired item configuration',
  'Mes.Item.Expired.Type': 'Expired item type',
  'Mes.Item.Expired.Price': 'Expired item price',
  'Mes.Item.Expired.Quantity': 'Expired item quantity',
  'Mes.Item.Expired.Name': 'Expired item name',
  'Mes.Item.Expired.Code': 'Expired item code',
  'Mes.Item.AlreadyExist.Id': 'Item ID already exists',
  'Mes.Item.AlreadyExist.Events': 'Item events already exist',
  'Mes.Item.AlreadyExist.OwnPreferentials': 'Item already has own preferentials',
  'Mes.Item.AlreadyExist.Preferentials': 'Preferentials for the item already exist',
  'Mes.Item.AlreadyExist.ItemStorages': 'Item storages already exist',
  'Mes.Item.AlreadyExist.SoldQuantity': 'Sold quantity for the item already exists',
  'Mes.Item.AlreadyExist.SortType': 'Item already has sort type',
  'Mes.Item.AlreadyExist.BusinessStatus': 'Business status for the item already exists',
  'Mes.Item.AlreadyExist.GenderType': 'Item gender type already exists',
  'Mes.Item.AlreadyExist.ItemGroup': 'Item group for the item already exists',
  'Mes.Item.AlreadyExist.ItemGroupId': 'Item group ID already exists',
  'Mes.Item.AlreadyExist.Collection': 'Item collection already exists',
  'Mes.Item.AlreadyExist.CollectionId': 'Collection ID for the item already exists',
  'Mes.Item.AlreadyExist.Overlay': 'Overlay for the item already exists',
  'Mes.Item.AlreadyExist.Thumbnail': 'Thumbnail for the item already exists',
  'Mes.Item.AlreadyExist.High': 'High item already exists',
  'Mes.Item.AlreadyExist.Abscissa': 'Item already exists',
  'Mes.Item.AlreadyExist.Ordinate': 'Item already exists',
  'Mes.Item.AlreadyExist.ConfigurationObj': 'Item configuration object already exists',
  'Mes.Item.AlreadyExist.Configuration': 'Item configuration already exists',
  'Mes.Item.AlreadyExist.Type': 'Item type already exists',
  'Mes.Item.AlreadyExist.Price': 'Item price already exists',
  'Mes.Item.AlreadyExist.Quantity': 'Quantity for the item already exists',
  'Mes.Item.AlreadyExist.Name': 'Item name already exists',
  'Mes.Item.AlreadyExist.Code': 'Item code already exists',
  'Mes.Item.NotEnoughLength.Id': 'Item ID does not have enough length',
  'Mes.Item.NotEnoughLength.Events': 'Item events do not have enough length',
  'Mes.Item.NotEnoughLength.OwnPreferentials': 'Item own preferentials do not have enough length',
  'Mes.Item.NotEnoughLength.Preferentials': 'Item preferentials do not have enough length',
  'Mes.Item.NotEnoughLength.ItemStorages': 'Item storages do not have enough length',
  'Mes.Item.NotEnoughLength.SoldQuantity': 'Item sold quantity does not have enough length',
  'Mes.Item.NotEnoughLength.SortType': 'Item sort type does not have enough length',
  'Mes.Item.NotEnoughLength.BusinessStatus': 'Item business status does not have enough length',
  'Mes.Item.NotEnoughLength.GenderType': 'Item gender type does not have enough length',
  'Mes.Item.NotEnoughLength.ItemGroup': 'Item group does not have enough length',
  'Mes.Item.NotEnoughLength.ItemGroupId': 'Item group ID does not have enough length',
  'Mes.Item.NotEnoughLength.Collection': 'Item collection does not have enough length',
  'Mes.Item.NotEnoughLength.CollectionId': 'Item collection ID does not have enough length',
  'Mes.Item.NotEnoughLength.Overlay': 'Item overlay does not have enough length',
  'Mes.Item.NotEnoughLength.Thumbnail': 'Item thumbnail does not have enough length',
  'Mes.Item.NotEnoughLength.High': 'Item height does not have enough length',
  'Mes.Item.NotEnoughLength.Abscissa': 'Item abscissa does not have enough length',
  'Mes.Item.NotEnoughLength.Ordinate': 'Item ordinate does not have enough length',
  'Mes.Item.NotEnoughLength.ConfigurationObj':
    'Item configuration object does not have enough length',
  'Mes.Item.NotEnoughLength.Configuration': 'Item configuration does not have enough length',
  'Mes.Item.NotEnoughLength.Type': 'Item type does not have enough length',
  'Mes.Item.NotEnoughLength.Price': 'Item price does not have enough length',
  'Mes.Item.NotEnoughLength.Quantity': 'Item quantity does not have enough length',
  'Mes.Item.NotEnoughLength.Name': 'Item name does not have enough length',
  'Mes.Item.NotEnoughLength.Code': 'Item code does not have enough length',
  'Mes.Item.NotEnough.Id': 'Item ID is not enough',
  'Mes.Item.NotEnough.Events': 'Item events are not enough',
  'Mes.Item.NotEnough.OwnPreferentials': 'Item own preferentials are not enough',
  'Mes.Item.NotEnough.Preferentials': 'Item preferentials are not enough',
  'Mes.Item.NotEnough.ItemStorages': 'Item storages are not enough',
  'Mes.Item.NotEnough.SoldQuantity': 'Item sold quantity is not enough',
  'Mes.Item.NotEnough.SortType': 'Item sort type is not enough',
  'Mes.Item.NotEnough.BusinessStatus': 'Item business status is not enough',
  'Mes.Item.NotEnough.GenderType': 'Item gender type is not enough',
  'Mes.Item.NotEnough.ItemGroup': 'Item group is not enough',
  'Mes.Item.NotEnough.ItemGroupId': 'Item group ID is not enough',
  'Mes.Item.NotEnough.Collection': 'Item collection is not enough',
  'Mes.Item.NotEnough.CollectionId': 'Item collection ID is not enough',
  'Mes.Item.NotEnough.Overlay': 'Item overlay is not enough',
  'Mes.Item.NotEnough.Thumbnail': 'Item thumbnail is not enough',
  'Mes.Item.NotEnough.High': 'Item height is not enough',
  'Mes.Item.NotEnough.Abscissa': 'Item abscissa is not enough',
  'Mes.Item.NotEnough.Ordinate': 'Item ordinate is not enough',
  'Mes.Item.NotEnough.ConfigurationObj': 'Item configuration object is not enough',
  'Mes.Item.NotEnough.Configuration': 'Item configuration is not enough',
  'Mes.Item.NotEnough.Type': 'Item type is not enough',
  'Mes.Item.NotEnough.Price': 'Item price is not enough',
  'Mes.Item.NotEnough.Quantity': 'Item quantity is not enough',
  'Mes.Item.NotEnough.Name': 'Item name is not enough',
  'Mes.Item.NotEnough.Code': 'Item code is not enough',
  'Mes.Item.OverLength.Id': 'Item ID is too long',
  'Mes.Item.OverLength.Events': 'Item events are too long',
  'Mes.Item.OverLength.OwnPreferentials': 'Own preferentials of the item are too long',
  'Mes.Item.OverLength.Preferentials': 'Item preferentials are too long',
  'Mes.Item.OverLength.ItemStorages': 'Item storages are too long',
  'Mes.Item.OverLength.SoldQuantity': 'Sold quantity of items that are too long',
  'Mes.Item.OverLength.SortType': 'Sort type of items that are too long',
  'Mes.Item.OverLength.BusinessStatus': 'Business status of items that are too long',
  'Mes.Item.OverLength.GenderType': 'Gender type of items that are too long',
  'Mes.Item.OverLength.ItemGroup': 'Item group of items that are too long',
  'Mes.Item.OverLength.ItemGroupId': 'Item group ID is too long',
  'Mes.Item.OverLength.Collection': 'Item collection is too long',
  'Mes.Item.OverLength.CollectionId': 'Collection ID is too long for the item',
  'Mes.Item.OverLength.Overlay': 'Overlay of items that are too long',
  'Mes.Item.OverLength.Thumbnail': 'Thumbnail of items that are too long',
  'Mes.Item.OverLength.High': 'High items that are too long',
  'Mes.Item.OverLength.Abscissa': 'Item is too long in the abscissa',
  'Mes.Item.OverLength.Ordinate': 'Item is too long',
  'Mes.Item.OverLength.ConfigurationObj': 'Configuration object of items that are too long',
  'Mes.Item.OverLength.Configuration': 'Configuration of items that are too long',
  'Mes.Item.OverLength.Type': 'Item type is too long',
  'Mes.Item.OverLength.Price': 'Price of items that are too long',
  'Mes.Item.OverLength.Quantity': 'Quantity of items that are too long',
  'Mes.Item.OverLength.Name': 'Item name is too long',
  'Mes.Item.OverLength.Code': 'Item code is too long',
  'Mes.Item.Required.Id': 'Item ID is required',
  'Mes.Item.Required.Events': 'Item events are required',
  'Mes.Item.Required.OwnPreferentials': 'Own preferentials are required for the item',
  'Mes.Item.Required.Preferentials': 'Item preferentials are required',
  'Mes.Item.Required.ItemStorages': 'Item storages are required',
  'Mes.Item.Required.SoldQuantity': 'Sold quantity is required for the item',
  'Mes.Item.Required.SortType': 'Sort type is required for the item',
  'Mes.Item.Required.BusinessStatus': 'Business status is required for the item',
  'Mes.Item.Required.GenderType': 'Gender type is required for the item',
  'Mes.Item.Required.ItemGroup': 'Item group is required for the item',
  'Mes.Item.Required.ItemGroupId': 'Item group ID is required',
  'Mes.Item.Required.Collection': 'Item collection is required',
  'Mes.Item.Required.CollectionId': 'Collection ID is required for the item',
  'Mes.Item.Required.Overlay': 'Overlay is required for the item',
  'Mes.Item.Required.Thumbnail': 'Thumbnail is required for the item',
  'Mes.Item.Required.High': 'High item is required',
  'Mes.Item.Required.Abscissa': 'Abscissa is required for the item',
  'Mes.Item.Required.Ordinate': 'Item is required',
  'Mes.Item.Required.ConfigurationObj': 'Configuration object is required for the item',
  'Mes.Item.Required.Configuration': 'Configuration is required for the item',
  'Mes.Item.Required.Type': 'Item type is required',
  'Mes.Item.Required.Price': 'Price is required for the item',
  'Mes.Item.Required.Quantity': 'Quantity is required for the item',
  'Mes.Item.Required.Name': 'Item name is required',
  'Mes.Item.Required.Code': 'Item code is required',
  'Mes.Item.MustBeEmpty.Id': 'Item ID must be empty',
  'Mes.Item.MustBeEmpty.Events': 'Item events must be empty',
  'Mes.Item.MustBeEmpty.OwnPreferentials': 'Own preferentials must be empty',
  'Mes.Item.MustBeEmpty.Preferentials': 'Preferentials must be empty',
  'Mes.Item.MustBeEmpty.ItemStorages': 'Item storages must be empty',
  'Mes.Item.MustBeEmpty.SoldQuantity': 'Sold quantity must be empty',
  'Mes.Item.MustBeEmpty.SortType': 'Sort type must be empty',
  'Mes.Item.MustBeEmpty.BusinessStatus': 'Business status must be empty',
  'Mes.Item.MustBeEmpty.GenderType': 'Gender type must be empty',
  'Mes.Item.MustBeEmpty.ItemGroup': 'Item group must be empty',
  'Mes.Item.MustBeEmpty.ItemGroupId': 'Item group ID must be empty',
  'Mes.Item.MustBeEmpty.Collection': 'Item collection must be empty',
  'Mes.Item.MustBeEmpty.CollectionId': 'Collection ID must be empty',
  'Mes.Item.MustBeEmpty.Overlay': 'Overlay must be empty',
  'Mes.Item.MustBeEmpty.Thumbnail': 'Thumbnail must be empty',
  'Mes.Item.MustBeEmpty.High': 'High item must be empty',
  'Mes.Item.MustBeEmpty.Abscissa': 'Abscissa must be empty',
  'Mes.Item.MustBeEmpty.Ordinate': 'Ordinate must be empty',
  'Mes.Item.MustBeEmpty.ConfigurationObj': 'Configuration object must be empty',
  'Mes.Item.MustBeEmpty.Configuration': 'Configuration must be empty',
  'Mes.Item.MustBeEmpty.Type': 'Item type must be empty',
  'Mes.Item.MustBeEmpty.Price': 'Price must be empty',
  'Mes.Item.MustBeEmpty.Quantity': 'Quantity must be empty',
  'Mes.Item.MustBeEmpty.Name': 'Item name must be empty',
  'Mes.Item.MustBeEmpty.Code': 'Item code must be empty',
  'Mes.Item.Invalid.Id': 'Invalid item ID',
  'Mes.Item.Invalid.Events': 'Invalid item events',
  'Mes.Item.Invalid.OwnPreferentials': 'Invalid own preferentials',
  'Mes.Item.Invalid.Preferentials': 'Invalid item preferentials',
  'Mes.Item.Invalid.ItemStorages': 'Invalid item storages',
  'Mes.Item.Invalid.SoldQuantity': 'Invalid sold quantity',
  'Mes.Item.Invalid.SortType': 'Invalid sort type',
  'Mes.Item.Invalid.BusinessStatus': 'Invalid business status',
  'Mes.Item.Invalid.GenderType': 'Invalid gender type',
  'Mes.Item.Invalid.ItemGroup': 'Invalid item group',
  'Mes.Item.Invalid.ItemGroupId': 'Invalid item group ID',
  'Mes.Item.Invalid.Collection': 'Invalid item collection',
  'Mes.Item.Invalid.CollectionId': 'Invalid collection ID',
  'Mes.Item.Invalid.Overlay': 'Invalid overlay',
  'Mes.Item.Invalid.Thumbnail': 'Invalid thumbnail',
  'Mes.Item.Invalid.High': 'Invalid high item',
  'Mes.Item.Invalid.Abscissa': 'Invalid abscissa',
  'Mes.Item.Invalid.Ordinate': 'Invalid ordinate',
  'Mes.Item.Invalid.ConfigurationObj': 'Invalid configuration object',
  'Mes.Item.Invalid.Configuration': 'Invalid configuration',
  'Mes.Item.Invalid.Type': 'Invalid item type',
  'Mes.Item.Invalid.Price': 'Invalid price',
  'Mes.Item.Invalid.Quantity': 'Invalid quantity',
  'Mes.Item.Invalid.Name': 'Invalid item name',
  'Mes.Item.Invalid.Code': 'Invalid item code',
  'Mes.Item.Repeated.Id': 'Repeated item ID',
  'Mes.Item.Repeated.Events': 'Repeated item events',
  'Mes.Item.Repeated.OwnPreferentials': 'Repeated item own preferentials',
  'Mes.Item.Repeated.Preferentials': 'Repeated item preferentials',
  'Mes.Item.Repeated.ItemStorages': 'Repeated item storages',
  'Mes.Item.Repeated.SoldQuantity': 'Repeated item sold quantity',
  'Mes.Item.Repeated.SortType': 'Repeated item sort type',
  'Mes.Item.Repeated.BusinessStatus': 'Repeated item business status',
  'Mes.Item.Repeated.GenderType': 'Repeated item gender type',
  'Mes.Item.Repeated.ItemGroup': 'Repeated item group',
  'Mes.Item.Repeated.ItemGroupId': 'Repeated item group ID',
  'Mes.Item.Repeated.Collection': 'Repeated item collection',
  'Mes.Item.Repeated.CollectionId': 'Repeated item collection ID',
  'Mes.Item.Repeated.Overlay': 'Repeated item overlay',
  'Mes.Item.Repeated.Thumbnail': 'Repeated item thumbnail',
  'Mes.Item.Repeated.High': 'Repeated item height',
  'Mes.Item.Repeated.Abscissa': 'Repeated item abscissa',
  'Mes.Item.Repeated.Ordinate': 'Repeated item ordinate',
  'Mes.Item.Repeated.ConfigurationObj': 'Repeated item configuration object',
  'Mes.Item.Repeated.Configuration': 'Repeated item configuration',
  'Mes.Item.Repeated.Type': 'Repeated item type',
  'Mes.Item.Repeated.Price': 'Repeated item price',
  'Mes.Item.Repeated.Quantity': 'Repeated item quantity',
  'Mes.Item.Repeated.Name': 'Repeated item name',
  'Mes.Item.Repeated.Code': 'Repeated item code',
  'Mes.Item.NotFound.Id': 'Item ID not found',
  'Mes.Item.NotFound.Events': 'Item events not found',
  'Mes.Item.NotFound.OwnPreferentials': 'Item own preferentials not found',
  'Mes.Item.NotFound.Preferentials': 'Item preferentials not found',
  'Mes.Item.NotFound.ItemStorages': 'Item storages not found',
  'Mes.Item.NotFound.SoldQuantity': 'Item sold quantity not found',
  'Mes.Item.NotFound.SortType': 'Item sort type not found',
  'Mes.Item.NotFound.BusinessStatus': 'Item business status not found',
  'Mes.Item.NotFound.GenderType': 'Item gender type not found',
  'Mes.Item.NotFound.ItemGroup': 'Item group not found',
  'Mes.Item.NotFound.ItemGroupId': 'Item group ID not found',
  'Mes.Item.NotFound.Collection': 'Item collection not found',
  'Mes.Item.NotFound.CollectionId': 'Item collection ID not found',
  'Mes.Item.NotFound.Overlay': 'Item overlay not found',
  'Mes.Item.NotFound.Thumbnail': 'Item thumbnail not found',
  'Mes.Item.NotFound.High': 'Item height not found',
  'Mes.Item.NotFound.Abscissa': 'Item abscissa not found',
  'Mes.Item.NotFound.Ordinate': 'Item ordinate not found',
  'Mes.Item.NotFound.ConfigurationObj': 'Item configuration object not found',
  'Mes.Item.NotFound.Configuration': 'Item configuration not found',
  'Mes.Item.NotFound.Type': 'Item type not found',
  'Mes.Item.NotFound.Price': 'Item price not found',
  'Mes.Item.NotFound.Quantity': 'Item quantity not found',
  'Mes.Item.NotFound.Name': 'Item name not found',
  'Mes.Item.NotFound.Code': 'Item code not found',
  'Mes.Item.NotFound': 'Item not found',
  'Mes.Item.WasUsed.Id': 'Used item ID',
  'Mes.Item.WasUsed.Events': 'Used item events',
  'Mes.Item.WasUsed.OwnPreferentials': 'Used item own preferentials',
  'Mes.Item.WasUsed.Preferentials': 'Used item preferentials',
  'Mes.Item.WasUsed.ItemStorages': 'Used item item storages',
  'Mes.Item.WasUsed.SoldQuantity': 'Used item sold quantity',
  'Mes.Item.WasUsed.SortType': 'Used item sort type',
  'Mes.Item.WasUsed.BusinessStatus': 'Used item business status',
  'Mes.Item.WasUsed.GenderType': 'Used item gender type',
  'Mes.Item.WasUsed.ItemGroup': 'Used item item group',
  'Mes.Item.WasUsed.ItemGroupId': 'Used item item group ID',
  'Mes.Item.WasUsed.Collection': 'Used item collection',
  'Mes.Item.WasUsed.CollectionId': 'Used item collection ID',
  'Mes.Item.WasUsed.Overlay': 'Used item overlay',
  'Mes.Item.WasUsed.Thumbnail': 'Used item thumbnail',
  'Mes.Item.WasUsed.High': 'Used item height',
  'Mes.Item.WasUsed.Abscissa': 'Used item abscissa',
  'Mes.Item.WasUsed.Ordinate': 'Used item ordinate',
  'Mes.Item.WasUsed.ConfigurationObj': 'Used item configuration object',
  'Mes.Item.WasUsed.Configuration': 'Used item configuration',
  'Mes.Item.WasUsed.Type': 'Used item type',
  'Mes.Item.WasUsed.Price': 'Used item price',
  'Mes.Item.WasUsed.Quantity': 'Used item quantity',
  'Mes.Item.WasUsed.Name': 'Used item name',
  'Mes.Item.WasUsed.Code': 'Used item code',
  'Mes.Item.WasUsed': 'Used item',
  'Mes.Gift.Delete.Failed': 'Failed to delete gift',
  'Mes.Gift.Delete.Successfully': 'Gift deleted successfully',
  'Mes.Gift.Distribute.Failed': 'Failed to distribute gift',
  'Mes.Gift.Distribute.Successfully': 'Gift distributed successfully',
  'Mes.Gift.Search.Failed': 'Failed to search for gifts',
  'Mes.Gift.Search.Successfully': 'Gift search successful',
  'Mes.Gift.ViewList.Failed': 'Failed to view gift list',
  'Mes.Gift.ViewList.Successfully': 'Gift list viewed successfully',
  'Mes.Gift.Purchase.Failed': 'Failed to purchase gift',
  'Mes.Gift.Purchase.Successfully': 'Gift purchased successfully',
  'Mes.Gift.ViewDetail.Failed': 'Failed to view gift details',
  'Mes.Gift.ViewDetail.Successfully': 'Gift details viewed successfully',
  'Mes.Gift.Update.Failed': 'Failed to update gift',
  'Mes.Gift.Update.Successfully': 'Gift updated successfully',
  'Mes.Gift.Create.Failed': 'Failed to create gift',
  'Mes.Gift.Create.Successfully': 'Gift created successfully',
  'Mes.Gift.Expired.Order': 'Expired gift order',
  'Mes.Gift.Expired.Kind': 'Expired gift kind',
  'Mes.Gift.Expired.Illustration': 'Expired gift illustration',
  'Mes.Gift.Expired.WeekQuantity': 'Expired gift week quantity',
  'Mes.Gift.Expired.Quantity': 'Expired gift quantity',
  'Mes.Gift.Expired.Code': 'Expired gift code',
  'Mes.Gift.AlreadyExist.Id': 'Gift ID already exists',
  'Mes.Gift.AlreadyExist.Name': 'Gift name already exists',
  'Mes.Gift.AlreadyExist.Code': 'Gift code already exists',
  'Mes.Gift.NotEnough.UsedQuantity': 'Insufficient used quantity of the gift',
  'Mes.Gift.NotEnough.Order': 'Insufficient gift order',
  'Mes.Gift.NotEnough.Kind': 'Insufficient gift kind',
  'Mes.Gift.NotEnough.Illustration': 'Insufficient gift illustration',
  'Mes.Gift.NotEnough.WeekQuantity': 'Insufficient week quantity of the gift',
  'Mes.Gift.NotEnough.Quantity': 'Insufficient gift quantity',
  'Mes.Gift.NotEnough.Type': 'Insufficient gift type',
  'Mes.Gift.NotEnough.AwardName': 'Insufficient gift award name',
  'Mes.Gift.NotEnough.Name': 'Insufficient gift name',
  'Mes.Gift.NotEnough.Code': 'Insufficient gift code',
  'Mes.Gift.OverLength.AwardName': 'Gift award name is too long',
  'Mes.Gift.OverLength.Name': 'Gift name is too long',
  'Mes.Gift.OverLength.Code': 'Gift code is too long',
  'Mes.Gift.Required.Id': 'Gift ID is required',
  'Mes.Gift.Required.Order': 'Gift order is required',
  'Mes.Gift.Required.Kind': 'Gift kind is required',
  'Mes.Gift.Required.Illustration': 'Gift illustration is required',
  'Mes.Gift.Required.WeekQuantity': 'Gift week quantity is required',
  'Mes.Gift.Required.Quantity': 'Gift quantity is required',
  'Mes.Gift.Required.Type': 'Gift type is required',
  'Mes.Gift.Required.AwardName': 'Gift award name is required',
  'Mes.Gift.Required.Name': 'Gift name is required',
  'Mes.Gift.Required.Code': 'Gift code is required',
  'Mes.Gift.MustBeEmpty.Id': 'Gift ID must be empty',
  'Mes.Gift.MustBeEmpty.UsedQuantity': 'Used quantity of the gift must be empty',
  'Mes.Gift.MustBeEmpty.Order': 'Gift order must be empty',
  'Mes.Gift.MustBeEmpty.Kind': 'Gift kind must be empty',
  'Mes.Gift.MustBeEmpty.Illustration': 'Gift illustration must be empty',
  'Mes.Gift.MustBeEmpty.WeekQuantity': 'Week quantity of the gift must be empty',
  'Mes.Gift.MustBeEmpty.Quantity': 'Gift quantity must be empty',
  'Mes.Gift.MustBeEmpty.Type': 'Gift type must be empty',
  'Mes.Gift.MustBeEmpty.AwardName': 'Gift award name must be empty',
  'Mes.Gift.MustBeEmpty.Name': 'Gift name must be empty',
  'Mes.Gift.MustBeEmpty.Code': 'Gift code must be empty',
  'Mes.Gift.Invalid.Id': 'Invalid gift ID',
  'Mes.Gift.Invalid.UsedQuantity': 'Invalid used quantity of the gift',
  'Mes.Gift.Invalid.Order': 'Invalid gift order',
  'Mes.Gift.Invalid.Kind': 'Invalid gift kind',
  'Mes.Gift.Invalid.Illustration': 'Invalid gift illustration',
  'Mes.Gift.Invalid.WeekQuantity': 'Invalid week quantity of the gift',
  'Mes.Gift.Invalid.Quantity': 'Invalid gift quantity',
  'Mes.Gift.Invalid.Type': 'Invalid gift type',
  'Mes.Gift.Invalid.AwardName': 'Invalid gift award name',
  'Mes.Gift.Invalid.Name': 'Invalid gift name',
  'Mes.Gift.Invalid.Code': 'Invalid gift code',
  'Mes.Gift.Repeated.Id': 'Repeated gift ID',
  'Mes.Gift.Repeated.UsedQuantity': 'Repeated used quantity of the gift',
  'Mes.Gift.Repeated.Order': 'Repeated gift order',
  'Mes.Gift.Repeated.Kind': 'Repeated Gift Type',
  'Mes.Gift.Repeated.Illustration': 'Repeated Gift Illustration',
  'Mes.Gift.Repeated.WeekQuantity': 'Repeated Gift Week Quantity',
  'Mes.Gift.Repeated.Quantity': 'Repeated Gift Quantity',
  'Mes.Gift.Repeated.Type': 'Repeated Gift Type',
  'Mes.Gift.Repeated.AwardName': 'Repeated Gift Award Name',
  'Mes.Gift.Repeated.Name': 'Repeated Gift Name',
  'Mes.Gift.Repeated.Code': 'Repeated Gift Code',
  'Mes.Gift.NotFound.Id': 'Gift ID Not Found',
  'Mes.Gift.NotFound.Order': 'Gift Order Not Found',
  'Mes.Gift.NotFound.Kind': 'Gift Kind Not Found',
  'Mes.Gift.NotFound.Illustration': 'Gift Illustration Not Found',
  'Mes.Gift.NotFound.WeekQuantity': 'Gift Week Quantity Not Found',
  'Mes.Gift.NotFound.Quantity': 'Gift Quantity Not Found',
  'Mes.Gift.NotFound.Type': 'Gift Type Not Found',
  'Mes.Gift.NotFound.AwardName': 'Gift Award Name Not Found',
  'Mes.Gift.NotFound.Name': 'Gift Name Not Found',
  'Mes.Gift.NotFound.Code': 'Gift Code Not Found',
  'Mes.Gift.NotFound': 'Gift Quantity Used Not Found',
  'Mes.Gift.WasUsed.Id': 'Used Gift ID',
  'Mes.Gift.WasUsed.UsedQuantity': 'Used Gift Quantity',
  'Mes.Gift.WasUsed.Order': 'Used Gift Order',
  'Mes.Gift.WasUsed.Kind': 'Used Gift Kind',
  'Mes.Gift.WasUsed.Illustration': 'Used Gift Illustration',
  'Mes.Gift.WasUsed.WeekQuantity': 'Used Gift Week Quantity',
  'Mes.Gift.WasUsed.Quantity': 'Used Gift Quantity',
  'Mes.Gift.WasUsed.Type': 'Used Gift Type',
  'Mes.Gift.WasUsed.AwardName': 'Used Gift Award Name',
  'Mes.Gift.WasUsed.Name': 'Used Gift Name',
  'Mes.Gift.WasUsed.Code': 'Used Gift Code',
  'Mes.Gift.WasUsed': 'Gift Used',
  'Mes.Gift.Blocked': 'Gift Blocked',
  'Mes.Gift.Invalid.Amount': 'Invalid Gift Amount',
  'Mes.Gift.Required.Amount': 'Required Gift Amount',
  'Mes.Gift.MustBeDivisible.Quantity': 'Gift Quantity must be divisible by Weekly Gift Quantity',
  'Mes.Voucher.Repeated.Ids': 'Repeated Voucher IDs',
  'Mes.Voucher.Required.Ids': 'Required Voucher IDs',
  'Mes.Voucher.Invalid.Ids': 'Invalid Voucher IDs',
  'Mes.Voucher.Repeated.Code': 'Repeated Voucher Code',
  'Mes.Voucher.Required.OperationStatus': 'Required Voucher Operation Status',
  'Mes.Voucher.Invalid.OperationStatus': 'Invalid Voucher Operation Status',
  'Mes.Voucher.NotEnough.Quantity': 'Not Enough Voucher Quantity',
  'Mes.Voucher.WasUsed': 'Voucher Used',
  'Mes.Week.NotEnough.Time': 'Not Enough Time for Weekly Rewards',
  'Mes.Week.WasDistribute.Gift': 'Week Already Distributed Gifts',
  'Mes.Setting.Required.Value': 'Required Setting Value',
  'Mes.Setting.Invalid.Value': 'Invalid Setting Value',
  'Mes.Setting.Update.Failed': 'Failed to Update Setting',
  'Mes.RankingSetting.Invalid.EndTime': 'Invalid Ranking Setting End Time',
  'Mes.RankingSetting.Invalid.DayOfWeek': 'Invalid Day of the Week',
  'Mes.RankingSetting.Started': 'Reward Settlement Time Has Started',
  //======================
  'Mes.Error.Delete.Failed': 'Failed to delete error',
  'Mes.Error.Delete.Successfully': 'Successfully deleted error',
  'Mes.Error.Distribute.Failed': 'Failed to distribute error',
  'Mes.Error.Distribute.Successfully': 'Successfully distributed error',
  'Mes.Error.Search.Failed': 'Failed to search error',
  'Mes.Error.Search.Successfully': 'Successfully searched error',
  'Mes.Error.ViewList.Failed': 'Failed to view error list',
  'Mes.Error.ViewList.Successfully': 'Successfully viewed error list',
  'Mes.Error.ViewDetail.Failed': 'Failed to view error detail',
  'Mes.Error.ViewDetail.Successfully': 'Successfully viewed error detail',
  'Mes.Error.Update.Failed': 'Failed to update error',
  'Mes.Error.Update.Successfully': 'Successfully updated error',
  'Mes.Error.Create.Failed': 'Failed to create error',
  'Mes.Error.Create.Successfully': 'Successfully created error',
  'Mes.Error.OverLength.Content': 'Error content is too long',
  'Mes.Error.OverLength.Contact': 'Contact information is too long',
  'Mes.Error.Required.Id': 'Error ID is required',
  'Mes.Error.Required.DeviceId': 'Device ID is required',
  'Mes.Error.Required.ErrorTypeId': 'Error type ID is required',
  'Mes.Error.Required.Status': 'Status is required',
  'Mes.Error.Required.Content': 'Error content is required',
  'Mes.Error.Required.Contact': 'Contact information is required',
  'Mes.Error.Invalid.Id': 'Invalid error ID',
  'Mes.Error.Invalid.DeviceId': 'Invalid device ID',
  'Mes.Error.Invalid.ErrorTypeId': 'Invalid error type ID',
  'Mes.Error.Invalid.Status': 'Invalid status',
  'Mes.Error.Invalid.Content': 'Invalid error content',
  'Mes.Error.Invalid.Contact': 'Invalid contact information',
  'Mes.Error.Repeated.Id': 'Duplicate error ID',
  'Mes.Error.Repeated.DeviceId': 'Duplicate device ID',
  'Mes.Error.Repeated.ErrorTypeId': 'Duplicate error type ID',
  'Mes.Error.NotFound.Id': 'Not found',
  'Mes.Error.NotFound.DeviceId': 'Device ID not found',
  'Mes.Error.NotFound.ErrorTypeId': 'Error type ID not found',
  'Mes.Error.NotFound': 'Error not found',
  'Mes.VoucherType.PaymentProcessingFailed': 'Failed to process payment',
  'Mes.VoucherType.Redeem.Failed': 'Failed to redeem voucher',
  'Mes.VoucherType.Blocked': 'This voucher redemption is blocked',
  'Mes.VoucherType.Redeem.Successfully': 'Successfully redeemed voucher',
  'Mes.VoucherType.Delete.Failed': 'Failed to delete',
  'Mes.VoucherType.Delete.Successfully': 'Delete Voucher successfully converted',
  'Mes.VoucherType.Search.Successfully': 'Successfully searched',
  'Mes.VoucherType.ViewList.Successfully': 'Successfully viewed list',
  'Mes.VoucherType.ViewDetail.Successfully': 'Successfully viewed details',
  'Mes.VoucherType.Update.Failed': 'Failed to update',
  'Mes.VoucherType.Update.Successfully': 'Update Voucher successfully converted',
  'Mes.VoucherType.Create.Failed': 'Failed to create',
  'Mes.VoucherType.Create.Successfully': 'Create a successful voucher',
  'Mes.VoucherType.Expired': 'Expired',
  'Mes.VoucherType.NotEnough.Endtime': 'Not enough time for the end',
  'Mes.VoucherType.NotEnough.StartTime': 'Not enough time for the start',
  'Mes.VoucherType.NotEnough.Quantity': 'Not enough quantity',
  'Mes.VoucherType.NotEnough.Amount': 'The amount received must be greater than or equal 1000 Vnđ',
  'Mes.VoucherType.NotEnough.Price': 'Not enough coins',
  'Mes.VoucherType.NotEnough.Content': 'Not enough content',
  'Mes.VoucherType.OverLength.Endtime': 'End time exceeds limit',
  'Mes.VoucherType.OverLength.UsedQuantity': 'Exceeded used quantity',
  'Mes.VoucherType.OverLength.Quantity': 'Exceeded quantity',
  'Mes.VoucherType.OverLength.Amount': 'Exceeded amount',
  'Mes.VoucherType.OverLength.Price': 'Exceeded specified coin amount',
  'Mes.VoucherType.OverLength.Content': 'Content is too long',
  'Mes.VoucherType.OverLength.Name': 'Name is too long',
  'Mes.VoucherType.Required.Id': 'Voucher type ID is required',
  'Mes.VoucherType.Required.BusinessStatus': 'Business status is required',
  'Mes.VoucherType.Required.SortType': 'Sort type is required',
  'Mes.VoucherType.Required.Endtime': 'End time is required',
  'Mes.VoucherType.Required.StartTime': 'Start time is required',
  'Mes.VoucherType.Required.UsedQuantity': 'Used quantity is required',
  'Mes.VoucherType.Required.Quantity': 'Quantity is required',
  'Mes.VoucherType.Required.Amount': 'Amount is required',
  'Mes.VoucherType.Required.Price': 'Coin price is required',
  'Mes.VoucherType.Required.Content': 'Content is required',
  'Mes.VoucherType.Required.Name': 'Name is required',
  'Mes.VoucherType.Invalid.Id': 'Invalid ID',
  'Mes.VoucherType.Invalid.BusinessStatus': 'Invalid business status',
  'Mes.VoucherType.Invalid.SortType': 'Invalid sort type',
  'Mes.VoucherType.Invalid.Endtime': 'Invalid end time',
  'Mes.VoucherType.Invalid.StartTime': 'Invalid start time',
  'Mes.VoucherType.Invalid.Quantity': 'Invalid quantity',
  'Mes.VoucherType.Invalid.Amount': 'Invalid amount',
  'Mes.VoucherType.Invalid.Price': 'Invalid price',
  'Mes.VoucherType.Invalid.Content': 'Invalid content',
  'Mes.VoucherType.Invalid.Name': 'Invalid name',
  'Mes.VoucherType.Repeated.Id': 'Duplicate ID',
  'Mes.VoucherType.NotFound.Id': 'Not found',
  'Mes.VoucherType.NotFound': 'Not found',
  'Mes.VoucherType.WasUsed': 'The conversion voucher has been used',
  'Mes.VoucherType.NotExistOrNotActive.PhoneNumber':
    'Phone number does not exist or is not activated',
  'Mes.VoucherType.Restricted.PhoneNumber': 'Phone number is restricted',

  'Mes.Item.Revoke.Successfully': 'Revoke items successfully',
  'Mes.Gift.Revoke.Successfully': 'Revoke gifts successfully',

  'Mes.Event.Update.Successfully': 'Update the program successfully',
  'Mes.Event.Create.Successfully': 'Create the program successfully',
  'Mes.Event.WasUsed': 'The reward program cannot be deleted',
  'Mes.Event.Delete.Successfully': 'Delete the program successfully',
  'Mes.Preferential.WasUsed': 'Duplicate time offers',
  'Mes.Preferential.Invalid.Preferentials': 'Invalid incentives',
  'Mes.Preferential.OverLength.CollectionTime': 'Preferential time is invalid',

  'Mes.Device.Create.Successfully': 'Add successful equipment',
  'Mes.Device.Update.Successfully': 'Successful device update',
  'Mes.Device.Delete.Successfully': 'Delete the device successfully',
  'Mes.Device.AlreadyExist.CollectHistories': 'Unable to delete the device collected ',
  'Mes.Gift.Transfer.Successfully': 'Successful gift transfer',
  'Mes.Voucher.Import.Successfully': 'Import voucher successfully',
  'Mes.Voucher.Delete.Successfully': 'Delete voucher successfully',
  'Mes.Voucher.Update.Successfully': 'Successful voucher update',
  'Mes.ItemGroup.Update.Successfully': 'Update successful item group',
  'Mes.User.WasUsed': 'Can not delete the active account',
  'Mes.Setting.Update.Successfully': 'Update time to close the rankings successfully',
  'Mes.User.NotFound.Email': 'No email found',
  'Mes.Item.DeleteCollectionHasItems.Failed': "Can't delete the collection with items",
  'Mes.Device.AlreadyExist.Code': 'The device code has existed',
  'Mes.Item.DeleteItemGroupHasItems.Failed': 'It is impossible to delete the group with items',
  'Mes.Event.AlreadyExist.Code': 'The program code has existed',

  'Mes.RankingSetting.Invalid.StartTime': 'The starting time is invalid ',
  'Mes.RankingSetting.NotAllowUpdate.StartTime': 'Do not allow the time to start update',
  'Mes.RankingSetting.Invalid.ClosingTime': 'Invalid closing time',
  'Mes.DeviceGroup.Create.Successfully': 'Create a successful equipment group',
  'Mes.DeviceGroup.Update.Successfully': 'Update successful equipment group',
  'Mes.DeviceGroup.Delete.Successfully': 'Delete successful equipment group',

  'Mes.DeviceGroup.AlreadyExist.Devices': 'Equipment groups have been used',

  'Mes.Guard.NotFound': 'Limit does not exist',
  'Mes.Guard.Update.Failed': 'Failed to update limit',
  'Mes.Guard.WasUsed': 'Limit has been used',
  'Mes.Guard.OverLength.Name': 'Limit name is too long',
  'Mes.Guard.OverLength.Description': 'Limit description is too long',
  'Mes.Guard.Required.FeatureGuards': 'Feature limit is required',
  'Mes.Guard.Create.Successfully': 'Limit created successfully',
  'Mes.Guard.Update.Successfully': 'Limit updated successfully',
  'Mes.Guard.Detail.Successfully': 'Limit details retrieved successfully',
  'Mes.Guard.Search.Successfully': 'Limit search successful',
  'Mes.Guard.Delete.Successfully': 'Limit deleted successfully',

  'Mes.Feature.NotFound': 'Feature does not exist',
  'Mes.Feature.Search.Successfully': 'Feature search successful',

  'Mes.FeatureGuard.NotFound': 'Feature guard does not exist',

  'Mes.GuardGroup.NotFound': 'Limit group does not exist',
  'Mes.GuardGroup.Update.Failed': 'Failed to update limit group',
  'Mes.GuardGroup.WasUsed': 'Limit group has been used',
  'Mes.GuardGroup.Required.FeatureGuardGroups': 'Feature guard group is required',
  'Mes.GuardGroup.AlreadyExist.Code': 'Limit group code already exists',
  'Mes.GuardGroup.Required.Name': 'Limit group name is required',
  'Mes.GuardGroup.OverLength.Name': 'Limit group name is too long',
  'Mes.GuardGroup.Required.Code': 'Limit group code is required',
  'Mes.GuardGroup.OverLength.Code': 'Limit group code is too long',
  'Mes.GuardGroup.OverLength.Description': 'Limit group description is too long',
  'Mes.GuardGroup.Create.Successfully': 'Limit group created successfully',
  'Mes.GuardGroup.Update.Successfully': 'Limit group updated successfully',
  'Mes.GuardGroup.Detail.Successfully': 'Limit group details retrieved successfully',
  'Mes.GuardGroup.Search.Successfully': 'Limit group search successful',
  'Mes.GuardGroup.Delete.Successfully': 'Limit group deleted successfully',

  'Mes.FeatureGuardGroup.NotFound': 'Feature guard group does not exist',
  'Mes.FeatureGuardGroup.Repeated.GuardId': 'Duplicate guard ID in the group',
  'Mes.FeatureGuardGroup.Required.FeatureRestrictions': 'Feature restrictions are required',

  'Mes.FeatureRestriction.Repeated.FeatureGuardId': 'Duplicate feature guard ID',
  'Mes.FeatureRestriction.Required.Type': 'Restriction type is required',
  'Mes.FeatureRestriction.Invalid.Type': 'Invalid restriction type',
  'Mes.FeatureRestriction.Required.Value': 'Restriction value is required',

  'Mes.GuardGroupParticipant.NotFound': 'Group participant does not exist',
  'Mes.GuardGroupParticipant.WasUsed': 'Group participant has been used',
  'Mes.GuardGroupParticipant.Required.EntityIds': 'Entity IDs are required',
  'Mes.GuardGroupParticipant.Repeated.EntityIds': 'Duplicate entity IDs',
  'Mes.GuardGroupParticipant.Required.StartTime': 'Start time is required',
  'Mes.GuardGroupParticipant.NotEnough.StartTime': 'Insufficient start time',
  'Mes.GuardGroupParticipant.NotEnough.EndTime': 'Insufficient end time',
  'Mes.GuardGroupParticipant.Required.EndTime': 'End time is required',
  'Mes.GuardGroupParticipant.Create.Successfully': 'Group participant created successfully',
  'Mes.GuardGroupParticipant.Update.Successfully': 'Group participant updated successfully',
  'Mes.GuardGroupParticipant.Detail.Successfully':
    'Group participant details retrieved successfully',
  'Mes.GuardGroupParticipant.Search.Successfully': 'Group participant search successful',
  'Mes.GuardGroupParticipant.Delete.Successfully': 'Group participant deleted successfully',

  'Mes.GuardGroupParticipantHistory.Search.Successfully':
    'Group participant history search successful',

  'Mes.FeatureTimeLimit.NotFound': 'Feature time limit does not exist',
  'Mes.FeatureTimeLimit.MustBeEmpty.Id': 'Feature time limit ID must not be entered',
};

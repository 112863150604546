export default {
  'device.router-name': 'Thiết bị',
  'device.title': 'Quản lý thiết bị',
  'device-add.name': 'Thêm thiết bị',
  'device-form-add': 'Thêm thiết bị',
  'device-form-edit': 'Cập nhật thiết bị',
  'device-edit.name': 'Cập nhật thiết bị',
  'device.deviceCode': 'Mã thiết bị',
  'device.deviceName': 'Tên thiết bị',
  'device.province': 'Tỉnh/Thành phố',
  'device.district': 'Quận/Huyện',
  'device.commune': 'Phường/Xã',
  'device.address': 'Địa chỉ lắp đặt',
  'device.password': 'Mật khẩu',
  'device.confirmPassword': 'Xác nhận mật khẩu',
  'device.capacity': 'Sức chứa',
  'device.status': 'Trạng thái hoạt động',
  'device.info.title': 'Thông tin thiết bị',
  'device.history.title': 'Lịch sử thu thập chai',
  'device.history.fullBarrel.title': 'Lịch sử đầy thùng',
  'device.history.collect.title': 'Lịch sử thu gom thùng chứa',
  'device.history.fullBarrel.totalCans': 'Tổng số lon',
  'device.history.fullBarrel.totalKg': 'Tổng số kg',
  'device-fullBarrel.createdAt': 'Thời gian đầy thùng',
  'device-fullBarrel.numberOfBottles': 'Số lượng chai',
  'device-fullBarrel.kgBottles': 'Số Kg chai',
  'device-fullBarrel.numberOfCans': 'Số lượng lon',
  'device-fullBarrel.kgCans': 'Số Kg lon',
  'device-fullBarrel.totalKg': 'Tổng số Kg',
  'device-collect.createdAt': 'Thời gian thu gom',
  'device-collect.numberOfBottles': 'Số chai',
  'device-collect.kgBottles': 'Số Kg chai',
  'device-collect.numberOfCans': 'Số lon',
  'device-collect.totalKg': 'Tổng số kg',
  'device-collect.collector.name': 'Người thu gom',
  'device-collect.code': 'Mã thu gom',
  'device-collect.cans': 'Lon',
  'device-collect.bottles': 'Chai',
  'device-collect.number': 'Số lượng',
  'device-collect.kgPet': 'kgPet/kgRPet',
  'device-collect.kgCans': 'kgAl/kgRAl',
  'device.report': 'Báo cáo thiết bị',
  'device.onlineStatus': 'Trạng thái hoạt động',

  'device.code': 'Mã thiết bị',
  'device.name': 'Tên thiết bị',
  'device.specificAddress': 'Địa chỉ lắp đặt',
  'device.obtainedQuantity': 'Số lượng chai đã thu',
  'device.validQuantity': 'Số chai của Aqua',
  'device.invalidQuantity': 'Số chai của brand khác',
  'device.bottlesCapacity': 'Thùng chai',
  'device.cansCapacity': 'Thùng lon',
  'device.containerStatus': 'Sức chứa',
  'device.operationStatus': 'Trạng thái hoạt động',
  'device.action': 'Hành động',
  'device-info.operationStatus': 'Trạng thái',

  'device.longitude': 'Kinh độ',
  'device.latitude': 'Vĩ độ',

  'device-info.name': 'Thông tin thiết bị',

  'device.capacity-empty': 'Thùng chứa rỗng',
  'device.recycle-full': 'Thùng rác đầy',
  'device.water-full': 'Thùng nước đầy',
  'device.bin-two-full': 'Cả hai thùng đầy',

  'device.status.containerStatus':
    '{status,select, 1 {Thùng chứa rỗng} 2 {Cả hai thùng đầy} 3 {Thùng rác đầy} 4 {Thùng nước đầy}  other {Không xác định}}',
  'device.status.operationStatus':
    '{status,select, 1 {Đang hoạt động} 2 {Dừng hoạt động} other {Không xác định}}',

  'device.history.time': 'Thời gian',

  'device-info.total-aqua': 'Tổng số chai Aqua',
  'device-info.gift': 'Tổng số lượng',
  'device-info.brand-different': 'Tổng số chai brand khác',

  'device-info.time': 'Thời gian',
  'device-info.invalidQuantity': 'Số lượng chai của brand khác',
  'device-info.validQuantity': 'Số lượng chai là Aqua',
  'device-info.obtainedQuantity': 'Tổng số lượng',
  'device-info.customer.nickName': 'Khách hàng',
  'device.content.delete': 'Bạn có chắc chắn muốn xóa thiết bị này không ?',
  'device.title.delete': 'Xóa thiết bị',
  'device.placeholder.latitude': 'Vui lòng nhập vĩ độ',
  'device.placeholder.longitude': 'Vui lòng nhập kinh độ',
  'device.info.empty': 'Bạn không có quyền xem thông tin thiết bị',
  'device.error': 'Trạng thái lỗi',
  'device.error.status':
    '{status,select, 0 {--} 1 {Lỗi mô tơ ép chai} 2 {Lỗi mô tơ nhả chai} 3 {Lỗi mô tơ ép chai <br/> Lỗi mô tơ nhả chai} 4 {Lỗi mô tơ nghiền chai} 5 {Lỗi mô tơ ép chai <br/> Lỗi mô tơ nghiền chai} 6 {Lỗi mô tơ nhả chai <br/> Lỗi mô tơ nghiền chai} 7 {Lỗi mô tơ ép chai <br/> Lỗi mô tơ nhả chai <br/> Lỗi mô tơ nghiền chai} other {Không xác định}}',
  'device.error.status.1': 'Lỗi mô tơ ép chai',
  'device.error.status.2': 'Lỗi mô tơ nhả chai',
  'device.error.status.3': 'Lỗi mô tơ nghiền chai',
  'device.error.status.4': 'Lỗi kẹt chai',

  'deviceGroups.name': 'Nhóm thiết bị',
  'deviceGroups.title': 'Quản lý nhóm thiết bị',
  'device-groups.delete.title': 'Xóa nhóm thiết bị',
  'device-groups.delete.content': 'Bạn có chắc chắn muốn xóa nhóm thiết bị này không?',
  'device-groups.deviceName': 'Tên nhóm thiết bị',
  'deviceGroups.information': 'Thông tin nhóm thiết bị',
  'deviceGroups.update': 'Câp nhật nhóm thiết bị',
  'deviceGroups.create': 'Tạo nhóm thiết bị',
  'device.deviceGroupId': 'Nhóm thiết bị',
  'device.deviceGroup.name': 'Nhóm thiết bị',

  'device-info.canQuantity': 'Số lượng lon',
  'device-info.total.canQuantity': 'Tổng số lượng lon',
};

export default {
  'customer.router.name': 'Customer',
  'customer.title': 'Customer management',
  'customer.update': 'Update customer',
  'customer.name': 'First and last name',
  'customer.nickName': 'Nick name',
  'customer.customerName': 'Customer name',
  'customer.phoneNumber': 'Phone number',
  'customer.fullName': 'First and last name',
  'customer.address': 'Address',
  'customer.province': 'Province/city',
  'customer.district': 'District',
  'customer.ward': 'Wards',
  'customer.status': 'Operating status',
  'customer.info': 'Customer information',

  'ItemWarehouse.tab.name': 'Warehouse',
  'VouchersWarehouse.tab.name': 'Voucher warehouse conversion',
  'GiftWarehouse.tab.name': 'Gift warehouse',
  'ScoreHistory.tab.name': 'History of points',
  'RankHistory.tab.name': 'Ranking history',

  'customer.validate-phone': 'Invalid phone number',

  'customer.greenCoin': 'Green coin </br> (remaining/total accumulation)',
  'customer.greenCoin.xlsx': 'Green coin (remaining/total accumulation)',
  'customer.greenCoin.used': 'Green coin used',

  'customer.lastWeekRanking': 'Ranked last week',
  'customer.specificAddress': 'Address',
  'customer.currentWeekRanking': 'Ranking of the current week',
  'customer.overallRanking': 'Overall ranking',
  'customer.action': 'Act',
  'customer.status.param':
    '{status,select, 1 {Active} 2 {Stop operation} 3 {Deleted} other {Unknown}}',
  'customer.accumulatedCoins': 'The accumulated coin number',
  'customer.remainingCoins': 'Number of coins used',

  'scoreHistory.device.specificAddress': 'Installation address',
  'scoreHistory.device.name': 'Device',
  'scoreHistory.time': 'Time',
  'scoreHistory.invalidQuantity': 'The number of other brand bottles',
  'scoreHistory.validQuantity': 'The number of bottles is Aqua',
  'scoreHistory.score': 'Number of points achieved',
  'scoreHistory.card.score': 'The total number of points achieved',
  'scoreHistory.card.invalidQuantity': 'The total number of other brand bottles',
  'scoreHistory.card.validQuantity': 'Total number of bottles Aqua',

  'giftWarehouse.status.type': '{status,select, 1 {Artifact} 2 {Voucher} other {unknown}}',
  'giftWarehouse.status.source':
    '{status,select, 1 {Source of Awards} 2 {Source of ranking} other {Unknown}}',
  'giftWarehouse.total-gift': 'Total number of gifts',
  'giftWarehouse.rank-gift': 'Ranking gifts',
  'giftWarehouse.different-gift': 'Other gifts',

  'giftWarehouse.awardName': 'Prize',
  'giftWarehouse.rank': 'Rank',
  'giftWarehouse.code': 'Gift code',
  'giftWarehouse.name': 'Gift name',
  'giftWarehouse.type': 'Gift type',
  'giftWarehouse.coin': 'Green coin number',
  'giftWarehouse.sourceGift': 'Gift source',
  'giftWarehouse.createdAt': 'Time',
  'giftWarehouse.giftRevokingStatus': 'Gift status',
  'giftWarehouse.status.giftRevokingStatus':
    '{status,select, 1 {Revoke} 2 {Gifts distributed} other {Unknown}}',
  'giftWarehouse.action': 'Act',
  'giftWarehouse.type-artifact': 'Artifacts',
  'giftWarehouse.type-voucher': 'voucher',
  'giftWarehouse.source-awardSource': 'Admin awarded',
  'giftWarehouse.source-rankSource': 'Weekly rankings',
  'giftWarehouse.source-Item-offers': 'Preferential items',
  'giftWarehouse.information': 'Gift information',

  'customers.createdAt.time': 'Time',

  'itemWarehouse.action': 'Act',
  'itemWarehouse.item.thumbnail': 'Image',
  'itemWarehouse.item.code': 'Item code',
  'itemWarehouse.item.name': 'Name of the item',
  'itemWarehouse.item.group.name': 'Item group',
  'itemWarehouse.item.quantity': 'greenCoin',
  'itemWarehouse.item.collection.name': 'Collection',
  'itemWarehouse.item.type': 'Type of item',
  'itemWarehouse.equippingStatus': 'Status',
  'itemWarehouse.createdAt': 'Buy time',
  'itemWarehouse.status.type': '{status,select, 1 {The normal type} 2 {Mystery} other {Unknown}}',
  'itemWarehouse.status.equippingStatus':
    '{status,select, 1 {Equipped} 2 {Not equipped} 3 {Recovered} other {Unknown}}',
  'itemWarehouse.status.normal': 'The normal type',
  'itemWarehouse.status.mystery': 'Mystery',
  'itemWarehouse.status.equipping': 'Equipped',
  'itemWarehouse.status.notEquipping': 'Not equipped',
  'itemWarehouse.status.revoked': 'Recovered',
  'itemWarehouse.card.itemQuantity': 'Total number of items',
  'itemWarehouse.card.coinQuantity': 'The total number of green coins bought items',
  'itemWarehouse.information': 'Message information',
  'customer.title.recall': 'Revoke items',
  'customer.content.recall': 'Are you sure you want to recover this item?',
  'customer.title.gift-recall': 'Gift recovery',
  'customer.content.gift-recall': 'Are you sure you want to recover this gift?',

  'vouchersWarehouse.price': 'Convert point (Green Coin)',
  'vouchersWarehouse.name': 'Voucher type',
  'vouchersWarehouse.receivingTime': 'Receiving time',
  'vouchersWarehouse.usageStatus': 'Status use',
  'vouchersWarehouse.totalVoucher': 'Total voucher',
  'vouchersWarehouse.totalGreencoin': 'The total number of conversion points',

  'vouchersWarehouse.used': 'Used',
  'vouchersWarehouse.unUsed': 'Unused',
  'vouchersWarehouse.normal': 'Normal',
  'vouchersWarehouse.prioritize': 'Priority',
  'vouchersWarehouse.information': 'Information warehouse voucher',
  'Green.Coin': 'Green Coin',
  'vouchersWarehouse.amountCoin': 'The amount/point received',
  'vouchersWarehouse.phoneNumber': 'Phone number received',
  'vouchersWarehouse.voucherPartner': 'Partner',
  'rankHistory.numberWeeksRanked': 'The total number of weeks has been ranked',
  'rankHistory.awardWinningRatings': 'Number of prizes',
  'rankHistory.title.closingTime': 'Time',
  'rankHistory.rank': 'Rank',
  'rankHistory.awardName': 'Prize',
  'rankHistory.coin': 'Number of Green Coin ranked',
  'rankHistory.week.id': 'Week',
  'rankHistory.closingTime': 'Bonus time',
  'customer.avatarPicture': 'AVT',
  'customer.userName': 'User name',
  'customer.createdAt': 'Date created',
  'customer.usedCoins': 'Coin used',
  'customer.currentWeekRanking.action': 'Action this week',

  // update blacklist 10/10/2024
  'customer.not-ban': 'Not restricted',
  'customer.banned': 'Previously restricted',
  'customer.banning': 'Currently restricted',
  'customer.status.ban': 'Restriction status',
  'customer.count-banned': 'Times restricted',
  'customer.status-banned': 'Restriction status',
  'blacklist.sidebar': 'Blacklist',
  'customer.impact-point-history': 'Impact point history',
  'impactPointHistory.action': 'Action',
  'impactPointHistory.content': 'Content',
  'impactPointHistory.effectTime': 'Impact time',
  'impactPointHistory.effectTarget': 'Impact target',
  'customer.restricted-information': 'Restricted information',
  'customer.restricted-history': 'Restriction history',
  'customer.restricted-history.pl': 'Item name / Item code',
  'restrictedInformation.action': 'Action',
  'restrictedInformation.effectTime': 'Impact time',
  'customer.restricted-information-current': 'Current restriction information',
  'restrictedInformation.group': 'Restriction group',
  'restrictedInformation.applyTime': 'Application time',
  'restrictedInformation.btn.cancel': 'Remove restriction',
  'restrictedInformation.btn.change': 'Change group',
  'restrictedInformation.popup.cancel.title': 'Confirm restriction removal',
  'restrictedInformation.popup.cancel.content':
    'The restriction has been applied to the user. Are you sure you want to remove this restriction?',
  'restrictedInformation.modal.add.title': 'Restrict user',
  'restrictedInformation.modal.edit.title': 'Change restriction group',
  'restrictedInformation.group.pl': 'Select restriction group',
  'restrictedInformation.modal.info.title': 'Restriction information upon change',
  'restrictedInformation.changeTime': 'Change time',

  'restrictedInformation.btn.add': 'Add restriction',
  'customer-and-black-list.sidebar': 'Customer',
  'blackList.group.code': 'Group code',
  'blackList.group.name': 'Group name',
  'blackList.limit': 'Limit quantity',
  'blackList.members': 'Number of users in the group',
  'restricted.modal.add.title': 'Add restriction group',
  'restricted.modal.edit.title': 'Update restriction group',
  'restricted.modal.info.title': 'Restriction group details',
  'restricted.code': 'Group code',
  'restricted.code.pl': 'Enter restriction group code',
  'restricted.name': 'Group name',
  'restricted.name.pl': 'Enter restriction group name',
  'restrictedInformation.type': 'Limit',
  'restrictedInformation.type.1': 'Voucher exchange limit',
  'restricted.all': 'All',
  'restricted.momo': 'Top-up Momo',
  'restricted.maximun': 'Maximum',
  'restricted.maximun.pl': 'Number of vouchers',
  'restricted.delete.title': 'Confirm restriction group deletion',
  'restricted.delete.content': 'Do you want to delete the selected restriction group?',
  'restricted.account': 'Username',
  'restricted.user': 'User name',
  'restricted.phone': 'Phone number',
  'restricted.rest-time': 'Remaining time',
  'restricted.typeVoucher': 'Voucher type',
  'restricted.limitTime': 'Limit time',
  'restricted.select.hour': 'By hour',
  'restricted.select.day': 'By day',
  'restricted.select.week': 'By week',
  'restricted.select.month': 'By month',
  'restricted.select.year': 'By year',
  'restricted.select.hour.pl': 'Enter number of hours',
  'restricted.select.day.pl': 'Enter number of days',
  'restricted.select.week.pl': 'Enter number of weeks',
  'restricted.select.month.pl': 'Enter number of months',
  'restricted.select.year.pl': 'Enter number of years',
  'restricted.pageList.delete.title': 'Confirm restriction removal',
  'restricted.pageList.delete.content':
    'The restriction has been applied to the user. Are you sure you want to remove this restriction?',
  'common.removeRestricted': 'Remove restriction',
  'restricted.modal.add.user': 'Restrict user',
  'routerBlackListPageList.name': 'Restricted user list',
  'restricted.select-user': 'Select user',
  'restricted.result-table': 'Selected results',
  'restricted.select-user.validate': 'Please select a user',
  'blacklist.sidebar-list': 'Blacklist',
  'restricted.empty': 'Empty',

  'coinTransactionSource.1': 'Collecting bottles',
  'coinTransactionSource.2': 'Sharing image',
  'coinTransactionSource.3': 'Deduction for exchanging Momo',
  'coinTransactionSource.5': 'Buying swap turns',
  'coinTransactionSource.6': 'Manually updated',
  'coinTransactionSource.7': 'Purchasing items',
  'coinTransactionSource.8': 'Deduction due to receiving gift from monthly leaderboard',
  'coinTransactionSource.9': 'Purchasing items from raising pet game',
  'coinTransactionSource.10': 'Purchasing exchangeable voucher',
  'coinTransactionSource.undefined': 'Undefined',
  'impactPointHistory.point': 'point',
  'impactPointHistory.customer': 'Customer',
  'restricted.effectTime.required': 'Enter time',
  'restrictedInformation.type.pl': 'Chose limit',

  'restricted.modal.add.title.sub': 'Group Information',
  'restricted.hour': 'hour',
  'restricted.day': 'day',
  'restricted.week': 'week',
  'restricted.month': 'month',
  'restricted.year': 'year',
  'restricted.title': 'restricted type',
  'restricted.desc.type': 'Voucher Type',
  'restricted.voucher.number': 'Number of vouchers received',
  'restricted.limit': 'maximum',
  'restricted.time': 'Time',
  'restricted.in': 'in',
  'restricted.form.limit': 'Limit',
  'restricted.add': 'Add limit',
  'featureRestrictions.required': 'Please enter the voucher number',
  'featureGuardTracking.status.active': 'Restricted',
  'featureGuardTracking.status.disabled': 'Unrestricted',
  'featureGuardTracking.status.update': 'Update',
  'featureRestrictions.validate':
    'The voucher number must be a natural number greater than or equal to 0',
  'limitTime.validate': 'The limit time must be a natural number greater than 0',
  'restrictedInformation.group.icon': 'Restricted user list',
  'impactPointHistory.system': 'System',
  'impactPointHistory.undefined': 'Undefined',

  'scoreHistory.canQuantity': 'The number of cans',
  'scoreHistory.total.canQuantity': 'The total number of cans',
};
